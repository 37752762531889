.K2sh5a_map {
  -webkit-user-select: none;
  user-select: none;
  aspect-ratio: 32 / 11;
  box-sizing: border-box;
  border-radius: 10px;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.K2sh5a_map img {
  image-rendering: pixelated;
  width: 100%;
  height: 100%;
}

.ewiFDW_overlay {
  -webkit-user-select: none;
  user-select: none;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(16, 1fr);
  gap: 1px;
  display: grid;
  position: absolute;
  inset: 0;
}

.BqQ1yq_screen {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 1px solid #000;
  display: grid;
  position: relative;
}

.BqQ1yq_screen:not(.BqQ1yq_visible) {
  background: #000;
}

.BqQ1yq_screen .BqQ1yq_coords {
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  position: absolute;
  inset: 0;
}

.BqQ1yq_screen:after {
  content: "";
  transition: all .15s ease-in-out;
  transition-property: opacity background-color;
  opacity: 0;
  background: #ffffff40;
  position: absolute;
  inset: 0;
}

.BqQ1yq_screen:hover:after {
  opacity: 1;
}

.BqQ1yq_screen .BqQ1yq_lockOverlay {
  pointer-events: none;
  color: #fff;
  opacity: 0;
  background: #00000080;
  justify-content: center;
  align-items: center;
  transition: opacity .15s ease-in-out;
  display: flex;
  position: absolute;
  inset: 0;
}

.BqQ1yq_screen.BqQ1yq_locked:after {
  background: #00000040;
}

.BqQ1yq_screen.BqQ1yq_locked .BqQ1yq_lockOverlay {
  opacity: 1;
}

.BqQ1yq_screen .BqQ1yq_lock {
  aspect-ratio: 1;
  width: 50%;
  height: 50%;
}

._1yzDOa_app {
  grid-template-rows: auto 2fr 3fr;
  height: 100vh;
  display: grid;
  overflow: hidden;
}

.Z10rOW_ribbon {
  z-index: 9999;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 1;
  backface-visibility: hidden;
  width: 80px;
  height: 80px;
  transition: opacity .15s ease-in-out;
  position: fixed;
  transform: rotate(45deg);
}

.Z10rOW_ribbon:hover {
  opacity: .75;
}

.Z10rOW_content {
  text-align: center;
  pointer-events: all;
  text-transform: uppercase;
  color: #333;
  background-color: #fafafa;
  width: 150px;
  padding: 7px 0;
  font: bold 15px sans-serif;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3px #0000004d;
}

.Z10rOW_ribbon.Z10rOW_bottom.Z10rOW_right, .Z10rOW_ribbon.Z10rOW_top.Z10rOW_left {
  transform: rotate(-45deg);
}

.Z10rOW_ribbon.Z10rOW_bottom {
  bottom: -10px;
}

.Z10rOW_ribbon.Z10rOW_top {
  top: -10px;
}

.Z10rOW_ribbon.Z10rOW_left {
  left: -10px;
}

.Z10rOW_ribbon.Z10rOW_right {
  right: -10px;
}

.lFfrAq_typography {
  line-height: 1;
}

.lFfrAq_h1 {
  margin: 0;
  font-size: 2em;
}

.lFfrAq_h2 {
  margin: 0;
  font-family: Roboto Slab, serif;
  font-weight: lighter;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

a, a:active {
  color: #fff;
}

.At6iEq_header {
  margin: 20px;
}

.onSpcG_forkMe {
  cursor: pointer;
  display: inline-flex;
  position: fixed;
  top: 0;
}

.onSpcG_forkMe .onSpcG_arm {
  transform-origin: 130px 106px;
}

.onSpcG_forkMe .onSpcG_arm, .onSpcG_forkMe .onSpcG_body {
  fill: currentColor;
}

.onSpcG_forkMe:hover .onSpcG_arm {
  animation: .56s ease-in-out onSpcG_octocat-wave;
}

.onSpcG_forkMe.onSpcG_right {
  right: 0;
}

.onSpcG_forkMe.onSpcG_left {
  left: 0;
  transform: scaleX(-1);
}

@keyframes onSpcG_octocat-wave {
  0%, 100% {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

._1xdQDW_slider {
  --background: #00000059;
  --size: 10px;
  background-image: linear-gradient(to right, var(--background) var(--size), transparent var(--size)), linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)), linear-gradient(to right, var(--background) var(--size), transparent var(--size)), linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)), linear-gradient(to bottom, transparent var(--size), var(--background) var(--size));
  background-size: calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size), 100% calc(100% - var(--size) * 3);
  background-repeat: repeat-x;
  background-position: 0 var(--size), top left, 0 calc(100% - var(--size)), bottom left, 0 var(--size);
  padding: calc(var(--size) * 3) calc(var(--size) * 2.5);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  display: flex;
}

._1xdQDW_slider ._1xdQDW_screen {
  -webkit-user-select: none;
  user-select: none;
  image-rendering: pixelated;
  aspect-ratio: 28 / 19;
  zoom: 2;
  transition: all .15s ease-in-out;
  transition-property: background-position-x background-position-y;
  background-color: #fcd9a9;
  background-size: 1600% 800%;
  border: 4px solid #272727;
  grid-template-rows: repeat(11, 1fr);
  grid-template-columns: repeat(16, 1fr);
  gap: 1px;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

._1xdQDW_slider._1xdQDW_visible ._1xdQDW_screen {
  opacity: 1;
}

.fihPHa_container {
  position: relative;
}

.fihPHa_tile {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 1px solid #00000026;
  width: 100%;
  height: 100%;
  position: relative;
}

.fihPHa_tile.fihPHa_disabled {
  pointer-events: none;
}

.fihPHa_tile.fihPHa_visible {
  opacity: 1;
}

.fihPHa_tile:after {
  content: "";
  opacity: 0;
  background: #00000040;
  transition: opacity .15s ease-in-out;
  position: absolute;
  inset: 0;
}

.fihPHa_tile:hover:after {
  opacity: 1;
}

._7_PifW_menu {
  z-index: 9999;
  opacity: 0;
  transition: all .15s ease-in-out;
  transition-property: top opacity;
  background: #000;
  border-radius: 10px;
  margin-left: 5px;
  padding: 0 10px;
  position: absolute;
  top: 0;
  left: 100%;
}

._7_PifW_menu:not(._7_PifW_opened) {
  pointer-events: none;
}

._7_PifW_menu._7_PifW_opened {
  opacity: 1;
  top: 3px;
}

.kRGYrG_sprite {
  pointer-events: none;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=index.873422ad.css.map */
